import "lazysizes";
import Swiper from 'swiper';
import WOW from 'wow.js';

const initSlider = () => {
  const indexBanner = new Swiper(".indexBanner .swiper-container", {
    loop: true,
    speed: 1100,
    observer: true,
    observeParents: true,
    parallax: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    watchSlidesProgress: true,
    pagination: {
      el: ".indexBanner .swiper-pagination",
      clickable: true,
    },
    on: {
      progress: function () {
        var swiper = this;
        for (var i = 0; i < swiper.slides.length; i++) {
          var slideProgress = swiper.slides[i].progress;
          var innerOffset = swiper.width * 0.5;
          var innerTranslate = slideProgress * innerOffset;
          swiper.slides[i].querySelector(".banner__img").style.transform =
            "translate3d(" + innerTranslate + "px, 0, 0)";
        }
      },

      touchStart: function () {
        var swiper = this;
        for (var i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = "";
        }
      },

      setTransition: function (speed) {
        var swiper = this;
        for (var i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = speed + "ms";
          swiper.slides[i].querySelector(".banner__img").style.transition =
            speed + "ms";
        }
      },
    },
  });
  const indexHotService = new Swiper(".indexHotService .swiper-container", {
    slidesPerView: 2,
    spaceBetween: 10,
    speed: 900,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1025: {
        spaceBetween: 0,
      },
      1360: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
    },
  });
  const indexCustomer = new Swiper(".indexCustomer .swiper-container", {
    slidesPerColumn: 1,
    slidesPerView: 3, // or 'auto'
    spaceBetween: 0,
    watchSlidesProgress: true,
    pagination: {
      el: ".indexCustomer .swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerColumn: 2,
        slidesPerView: 3, // or 'auto'
      },
      1025: {
        slidesPerColumn: 2,
        slidesPerView: 4, // or 'auto'
        slidesPerGroup: 4,
      },
      1200: {
        slidesPerColumn: 2,
        slidesPerView: 5, // or 'auto'
        slidesPerGroup: 5,
      },
    },
  });
  const aboutCustomer = new Swiper(".aboutCustomer__slider .swiper-container", {
    slidesPerView: 1,
    speed: 800,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      disableOnInteraction: false,
      delay: 3400,
    },
    pagination: {
      el: ".aboutCustomer__slider .swiper-pagination",
      clickable: true,
    },
  });
  const ServiceDetail = new Swiper(".serviceDetail__img .swiper-container", {
    slidesPerView: 1,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      disableOnInteraction: false,
      delay: 3400,
    },
    pagination: {
      el: ".serviceDetail .swiper-pagination",
      clickable: true,
    },
  });
  const AfBe = new Swiper(".afbe .swiper-container", {
    slidesPerView: 1,
    simulateTouch: false,
    effect: "fade",
    navigation: {
      nextEl: ".afbe .swiper-button-next",
      prevEl: ".afbe .swiper-button-prev",
    },
  });
};

const HeaderNav = () => {
  $(".nav__item--hasSub > .nav__link").on("click", function (e) {
    e.preventDefault();
    if (window.innerWidth <= 1024) {
      $(this).toggleClass("active");
      $(this).parents(".nav__item--hasSub").toggleClass("active");
    }
  });
  $(".header--mobile .header__toggle").on("click", function (e) {
    e.preventDefault();
    $(".header").toggleClass("active");
    $(".header")
      .find(".active")
      .each(function () {
        $(this).removeClass("active");
      });
  });
  $(".nav__item--hasSub .nav__back").on("click", function (e) {
    e.preventDefault();
    $("this").parents("active");
    $(".header")
      .find(".active")
      .each(function () {
        $(this).removeClass("active");
      });
  });
};

const initWoW = () => {
  return new WOW({
    boxClass: "wow", // animated element css class (default is wow)
    animateClass: "animated", // animation css class (default is animated)
    offset: 0, // distance to the element when triggering the animation (default is 0)
    mobile: false, // trigger animations on mobile devices (default is true)
    live: true, // act on asynchronously loaded content (default is true)
    callback: function (box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    },
    scrollContainer: null, // optional scroll container selector, otherwise use window,
    resetAnimation: false, // reset animation on end (default is true)
  }).init();
};

const afbe = () => {
  var divisor = document.getElementById("divisor"),
    handler = document.getElementById("handle"),
    slider = document.getElementById("slider");

  function moveDivisor() {
    handler.style.left = slider.value + "%";
    divisor.style.width = slider.value + "%";
  }

  if (document.readyState === "complete") {
    moveDivisor();
  }
};

document.addEventListener("DOMContentLoaded", () => {
  initSlider();
  HeaderNav();
  afbe();
  initWoW();
});
